import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// -------- custom icons and images -----------
import GliderLogo from "../../../Assets/qgliderlogo.png";
// import Doctor from "../Login/Doctor.png";
import PatientsImage from "../../../Assets/LoginImage/Login_Qg.png";
// import Doctor from "../Login/Login_Qg2.png";
import UserIcon from "../../../Assets/icons/userIcon.svg";
import HospitalIcon from "../../../Assets/icons/Hospitalicon.svg";
import TelephoneIcon from "../../../Assets/icons/Telephoneicon.svg";
import MobileIcon from "../../../Assets/icons/Mobileicon.svg";
import EmailIcon from "../../../Assets/icons/Emailicon.svg";
import LocationIcon from "../../../Assets/icons/Locationicon.svg";
import LockIcon from "../../../Assets/icons/Lockicon.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import Controller from "../../../Controller/ApiController";
import validationRegExp from "../../../Configuration/ValidationConfig";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function SignupDoctor() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [clinic, setClinic] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [duplicateHospital, setDuplicateHospital] = useState();
  const [duplicateIVR, setDuplicateIVR] = useState();
  const [duplicateMobile, setDuplicateMobile] = useState();
  const [duplicateEmail, setDuplicateEmail] = useState();
  const [duplicateUsername, setDuplicateUsername] = useState();
  const [mobileErrorText, setMobileErrorText] = useState();
  const [contactErrorText, setContactErrorText] = useState();
  const [emailErrorText, setEmailErrorText] = useState();
  const [usernameErrorText, setUsernameErrorText] = useState();
  const [passwordErrorText, setPasswordErrorText] = useState();
  const [mobileNumber, setMobileNumber] = useState("");
  const [ivr_ContactNumber, setIvr_ContactNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [name, setName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loaderopen, setloaderOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    fullName: fullName,
    hospital_name: "",
    hospitalName: "default",
    ivr_ContactNumber: ivr_ContactNumber,
    mobileNumber: mobileNumber,
    email: "",
    state: "default",
    state_name: "",
    city: "",
    city_name: "",
    address: "",
    name: name,
    check: "",
  });

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isPassworTooltipOpen, setIsPasswordTooltipOpen] = useState(false);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const mobilePatternSignup = (e) => {
    const { value } = e.target;
    let validInput = true;
    let errorMessage = "";
    if (new RegExp(/^[\d]{0,10}$/).test(value)) {
      setMobileErrorText("");
      setDuplicateMobile("");
      setMobileNumber(value);
      setFormData({ ...formData, mobileNumber: value });

      // let mobile = "";
      // let firstIndex = value[0] == undefined ? "" : value[0];
      // var repeat = true;
      // for (var i = 0; i < value.length; i++) {
      //   if (firstIndex != value[i]) {
      //     repeat = false;
      //   }
      // }
      // for (let i = 0; i < value.length; i++) {
      //   mobile += value[i] != " " ? value[i] : "";
      // }
      // if (mobile.length > 9 || mobile == "") {
      //   if (repeat && mobile != "") {
      //     setMobileErrorText("Invalid Number");
      //   } else {
      //     setMobileErrorText("");
      //     setFormData({ ...formData, mobileNumber: value });
      //   }
      // } else {
      //   setMobileErrorText("Mobile number must be 10 digit");
      // }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      mobileNumber: validInput ? "" : errorMessage,
    }));
  };

  const ivrPatternSignup = (e) => {
    const { value } = e.target;
    let validInput = true;
    let errorMessage = "";
    if (new RegExp(/^[\d]{0,15}$/).test(value)) {
      setContactErrorText("");
      setDuplicateIVR("");
      setIvr_ContactNumber(value);
      setFormData({ ...formData, ivr_ContactNumber: value });

      // let mobile = "";
      // let firstIndex = value[0] == undefined ? "" : value[0];
      // var repeat = true;
      // for (var i = 0; i < value.length; i++) {
      //   if (firstIndex != value[i]) {
      //     repeat = false;
      //   }
      // }
      // for (let i = 0; i < value.length; i++) {
      //   mobile += value[i] != " " ? value[i] : "";
      // }

      // if (mobile.length > 9 || mobile == "") {
      //   if (repeat && mobile != "") {
      //     setContactErrorText("Invalid Number");
      //   } else {
      //     setContactErrorText("");
      //     setFormData({ ...formData, ivr_ContactNumber: value });
      //   }
      // } else {
      //   setContactErrorText("Contact number must be 10 digit");
      // }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      ivr_ContactNumber: validInput ? "" : errorMessage,
    }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onChangeState = async (event) => {
    const { value, name } = event.target;
    let validInput = true;
    let errorMessage = "";
    console.log(event.target);
    let state_name = "";
    state.map((val) => {
      if (val.state_uid == value) state_name = val.state_name;
    });
    setFormData({ ...formData, state: value, state_name: state_name });
    let uid = value;

    try {
      let response = await Controller.ApiController("GET", "/city/" + uid);
      console.log(response);
      const City = response;
      if (Array.isArray(City)) {
        setCity(City);
      } else console.error("Invalid city data format:", City);
    } catch (error) {
      console.log(error);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      state_name: validInput ? "" : errorMessage,
    }));
  };

  const onChangeCity = (event) => {
    let errorMessage = "";

    setFormData({ ...formData, city_name: event.target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      city_name: formData.hospital_name ? "" : errorMessage,
    }));
  };

  const onChangeHospital = (event) => {
    let errorMessage = "";
    setFormData({ ...formData, hospital_name: event.target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      hospital_name: formData.hospital_name ? "" : errorMessage,
    }));
  };

  useEffect(() => {
    const fetchClinicData = async () => {
      try {
        let response = await Controller.ApiController(
          "GET",
          "/clinic?un_registered_clinic=true"
        );
        console.log(response);
        const ClinicData = response.data;
        if (Array.isArray(ClinicData)) {
          setClinic(ClinicData);
        } else console.error("Invalid clinic data format:", ClinicData);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchStateData = async () => {
      try {
        let response = await Controller.ApiController("GET", "/state");
        console.log(response);
        const State = response;
        if (Array.isArray(State)) {
          setState(State);
        } else console.error("Invalid state data format:", State);
      } catch (error) {
        console.log(error);
      }
    };

    fetchStateData();
    fetchClinicData();
  }, []);

  const emailValidation = (value) => {
    let emailpattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let emailTextPattern = new RegExp(
      /[^0-9]| [A-Za-z0-9#$%&'*+-/=?^_`{|.]{1,}$/
    );
    let emailTextIndex = value.indexOf("@");
    let slicedEmail = value.slice(0, emailTextIndex);
    if (emailpattern.test(value) && emailTextPattern.test(slicedEmail)) {
      // errorMessage = validInput ? "" : ""
      setDuplicateEmail("");
    } else if (
      !emailpattern.test(value) ||
      !emailTextPattern.test(slicedEmail)
    ) {
      if (value == "") {
        // errorMessage = validInput ? "" : ""
        setDuplicateEmail("");
      } else {
        // errorMessage = validInput ? "" : "Invalid email"
        setDuplicateEmail("Invalid email address");
      }
    }
  };

  const passwordValidation = (value) => {
    let validInput =
      /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/.test(
        value
      );
    if (!validInput) {
      setPasswordErrorText("Invalid password");
      setErrors((prevErrors) => ({
        ...prevErrors,
        check: "",
      }));
    } else {
      setPasswordErrorText("");
      setErrors((prevErrors) => ({
        ...prevErrors,
        check: "",
      }));
    }
  };

  const userNameValidation = (value) => {
    if (new RegExp(/^[a-zA-Z0-9]{8,20}$/).test(value)) {
      // setName(value);
      setUsernameErrorText("");
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    } else {
      setUsernameErrorText("Invalid username");

      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }
  };

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    let validInput = true;
    let errorMessage = "";
    if (formSubmitted) {
      if (name === "hospitalName") {
        validInput = /^[a-zA-Z0-9 ]+$/.test(value);
        errorMessage = validInput ? "" : "Invalid hospital or clinic name";
      } else if (name === "email") {
        setDuplicateEmail("");
      } else if (name === "check") {
        setPasswordErrorText("");
        validInput =
          /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/.test(
            value
          );
        errorMessage = validInput ? "" : "Invalid password";
      } else {
        validInput = /^[a-zA-Z0-9\s,.'/-]+(?:[\s-][a-zA-Z0-9\s,.'/-]+)*$/.test(
          value
        );
        errorMessage = validInput ? "" : "Invalid personal details";
      }

      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   [name]: validInput ? "" : errorMessage,
      // }));
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const ivrNumberValidation = (ivrNumber, allSameIverNumber) => {
    if (ivrNumber.length > 7) {
      if (allSameIverNumber) {
        setContactErrorText("Invalid contact number");
      } else {
        setContactErrorText("");

      }
    } 
    else {
      setContactErrorText("Contact number must have minimum 8 digit and maximum 15 digits");
    }
  };

  const mobileNumberValidation = (mobileNumber, allSameMobNumber) => {
    if (mobileNumber.length > 9 || mobileNumber == "") {
      if (allSameMobNumber) {
        setMobileErrorText("Invalid mobile number");
      } else {
        setMobileErrorText("");
      }
    } else {
      setMobileErrorText("Mobile number must be 10 digit");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const formErrors = {};
    setFormSubmitted(true);
    console.log("formData :", formData);
    const ivrNumber = formData.ivr_ContactNumber;
    const mobileNumber = formData.mobileNumber;
    const mobileNumberString = mobileNumber.toString();
    const allSameMobNumber = mobileNumberString
      .split("")
      .every((digit) => digit === mobileNumberString[0]);
    const ivrNumberString = ivrNumber.toString();
    const allSameIverNumber = ivrNumberString
      .split("")
      .every((digit) => digit === ivrNumberString[0]);
    const email = formData.email;
    const password = formData.check;
    const userName = formData.name;

  

    if (ivrNumber !== "") {
      ivrNumberValidation(ivrNumber, allSameIverNumber);
    }
    if (mobileNumber !== "") {
      mobileNumberValidation(mobileNumber, allSameMobNumber);
    }
    emailValidation(email);
    if (password !== "") {
      passwordValidation(password);
    }
    if (userName !== "") {
      userNameValidation(userName);
    }

    console.log("formData, ", formData);

    if (!formData.fullName || !formData.fullName.trim()) {
      formErrors.fullName = "Full Name is required";
    }
    if (!formData.hospital_name || formData.hospital_name.length === 0) {
      formErrors.hospital_name = "Hospital name is required";
    }

    if (!formData.ivr_ContactNumber || !formData.ivr_ContactNumber.trim()) {
      formErrors.ivr_ContactNumber = "IVR contact number is required";
    }else if (ivrNumber.length > 7) {
      if (allSameIverNumber) {
        formErrors.ivr_ContactNumber = "IVR contact number is required";
      } else {

        
      }
    } else {
      formErrors.ivr_ContactNumber = "IVR contact number is required";
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formErrors.mobileNumber = "Mobile number is required";
    } else if (mobileNumber.length > 9 || mobileNumber == "") {
      if (allSameMobNumber) {
        formErrors.mobileNumber = "Mobile number is required";
      } else {
      }
    } else {
      formErrors.mobileNumber = "Mobile number is required";
    }
    
    if (!formData.email || !formData.email.trim()) {
      formErrors.email = "Email ID is required";
    } else {
      let emailpattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      let emailTextPattern = new RegExp(
        /[^0-9]| [A-Za-z0-9#$%&'*+-/=?^_`{|.]{1,}$/
      );
      let emailTextIndex = formData.email.indexOf("@");
      let slicedEmail = formData.email.slice(0, emailTextIndex);
      if (
        emailpattern.test(formData.email) &&
        emailTextPattern.test(slicedEmail)
      ) {
        // errorMessage = validInput ? "" : ""
      } else if (
        !emailpattern.test(formData.email) ||
        !emailTextPattern.test(slicedEmail)
      ) {
        if (formData.email == "") {
          // errorMessage = validInput ? "" : ""
        } else {
          // errorMessage = validInput ? "" : "Invalid email"
          formErrors.email = "Email ID is required";
        }
      }
    }
    if (!formData.state_name || formData.state_name.length === 0) {
      formErrors.state_name = "State is required";
    }
    if (!formData.city || formData.city.length === 0) {
      formErrors.city = "Village/Town/City is required";
    }
    if (!formData.address || !formData.address.trim()) {
      formErrors.address = "Address is required";
    }
    if (!formData.name || !formData.name.trim()) {
      formErrors.name = "Username is required";
    } else {
      const validInput = new RegExp(/^[a-zA-Z0-9._]{8,20}$/).test(
        formData.name
      );
      if (!validInput) {
        formErrors.name = "Invalid Username";
      }
    }
    if (!formData.check || !formData.check.trim()) {
      formErrors.check = "Password is required";
    } else {
      const validInput =
        /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/.test(
          formData.check
        );
      if (!validInput) {
        formErrors.check = "Invalid password";
      }
    }

    const hasErrors = Object.keys(formErrors).length > 0;

  

    setErrors(formErrors);

    
    try {
      if (!hasErrors) {
        const formObj = {
          full_name: formData.fullName,
          hospital_or_clinic_name: formData.hospital_name,
          ivr_contact_number: formData.ivr_ContactNumber,
          mobile_number: formData.mobileNumber,
          email: formData.email,
          state_uid: formData.state,
          city: formData.city,
          address: formData.address,
          user_name: formData.name,
          password: formData.check,
        };

        console.log("Form Data:", formObj);
        handleLoaderOpen();
        let res = await Controller.ApiController(
          "POST",
          "/doctor/register/",
          "",
          formObj
        );
        handleLoaderClose();
        console.log(res);
        if (res.type === "success") {
          // sessionStorage.setItem("OTP", res.data.data.otp);
          localStorage.setItem("user_type", "SUPER_ADMIN");
          sessionStorage.setItem("otp", res.data.otp);
          sessionStorage.setItem("uid", res.data.user.doctor_uid);
          localStorage.setItem("DoctorUid", res.data.user.doctor_uid);
          localStorage.setItem("user_name", res.data.user.user_name);
          Alert(
            "success",
            "User registered successfully and OTP has been sent to the mobile number"
          );
          localStorage.setItem("full_name", formObj.full_name);
          localStorage.setItem(
            "hospital_name",
            formObj.hospital_or_clinic_name
          );
          setTimeout(() => {
            navigate(`/signupotp`, {
              state: {
                full_name: formObj.full_name,
                mobile_number: formObj.mobile_number,
              },
            });
          }, 3700);
        } else {
          console.error("API error:", res.error);
          if (res.error.data && res.error.data) {
            const duplicateData = res.error.data;

            // Set error messages for specific fields
            setDuplicateHospital(
              duplicateData.hospital_or_clinic_name
                ? "Hospital/clinic name already registered"
                : ""
            );
            setDuplicateIVR(
              duplicateData.ivr_contact_number
                ? "IVR contact number already registered"
                : ""
            );
            setDuplicateMobile(
              duplicateData.mobile_number
                ? "Mobile number already registered"
                : ""
            );
            setDuplicateEmail(
              duplicateData.email ? "Email already registered" : ""
            );
            setDuplicateUsername(
              duplicateData.user_name ? "Username already registered" : ""
            );
          }
        }
      }
    } catch (error) {
      console.error("Exception:", error);
    }
  };

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event && event.keyCode === 13) {
  //       handleSubmit(event);
  //     }
  //   };
  
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <Grid container component="main" sx={{ height: "100vh", padding: "20px" }}>
      {/* <CssBaseline /> */}
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        elevation={6}
        sx={{
          padding: {
            xl: "0px 120px",
            xs: "0px 0px",
            lg: "0px 100px",
            md: "0px 50px",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <img src={GliderLogo} alt={GliderLogo + ".logo"} width={"155px"} />
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <h2 sx={{ fontWeight: "600", paddingBottom: "25px" }}>Sign up</h2>
            <Box sx={{ marginTop: "20px" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                If you already have an account registered
              </Typography>
              <Box display={"flex"}>
                <Typography>You can</Typography>
                <Link
                  to="/login"
                  style={{
                    textDecoration: "none",
                    color: "#0C21C1",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "4px",
                  }}
                >
                  Login here !
                </Link>
              </Box>
            </Box>
          </Box>
          <Box

inputProps={{
  autocomplete: 'new-password',
  form: {
    autocomplete: 'off',
  },
}}
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              mt: 2,
              maxHeight: { xs: "auto", sm: "54vh" },
              overflowY: { xs: "", sm: "auto" },
              overflowX: { xs: "", sm: "hidden" },
              paddingRight: "10px",
            }}
            autoComplete="off"
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "2%" }}
            >
              <TextField
                inputProps={{ maxLength: 30 }}
                autoComplete="off"
                fullWidth
                id="filled-required-fullName"
                label="Full Name *"
                name="fullName"
                value={fullName}
                placeholder="Enter full name"
                variant="standard"
                error={!!errors.fullName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <img src={UserIcon} style={{ color: "#000000" }} alt="" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999",
                  },
                  "& label": {
                    marginTop: "1%",
                    fontSize: "18px",
                    fontWeight: "500",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
                onChange={(e) => {
                  setErrors((prev) => {
                    return { ...prev, fullname: "" };
                  });

                  if (
                    new RegExp(/^(?! )[a-zA-Z ]{0,50}$/).test(e.target.value)
                  ) {
                    setFullName(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      fullName: "",
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      fullName: "Invalid Full Name",
                    }));
                  }
                  setFormData((prevData) => ({
                    ...prevData,
                    fullName: e.target.value,
                  }));
                }}
              />

              {/* <FormHelperText style={{ color: "red" }}>{errors.fullName}</FormHelperText> */}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "2%" }}
            >
              <FormControl
                autoComplete="off"
                fullWidth
                variant="standard"
                sx={{
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999",
                  },
                  "& label": {
                    marginTop: "1%",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#999999",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
              >
                <InputLabel
                  id="hospitalName-label"
                  error={!!errors.hospital_name}
                >
                  Hospital or Clinic Name *
                </InputLabel>
                <Select
                  labelId="hospitalName-label"
                  id="hospitalName-select"
                  label="Hospital or Clinic Name"
                  value={formData.hospital_name || formData.hospitalName}
                  error={!!errors.hospital_name}
                  onChange={onChangeHospital}
                  MenuProps={{
                    PaperProps: { style: { maxHeight: "20%", padding: "0px" } },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={HospitalIcon}
                        style={{ color: "#000000" }}
                        alt=""
                      />
                    </InputAdornment>
                  }
                >
                  <MenuItem
                    error={!!errors.hospitalName}
                    value={"default"}
                    disabled
                    sx={{ display: "none" }}
                  >
                    {" "}
                    <span style={{ color: "#999999", fontWeight: 100 }}>
                      Select hospital or clinic name
                    </span>
                  </MenuItem>
                  {clinic?.map((clinic_name, index) => (
                    <MenuItem key={index} value={clinic_name.clinic_name}>
                      {clinic_name.clinic_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {duplicateHospital ? (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  {duplicateHospital}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "2%" }}
            >
              <TextField
                autoComplete="off"
                fullWidth
                id="filled-required-ivr_ContactNumber"
                label="Contact Number for IVR *"
                name="ivr_ContactNumber"
                value={ivr_ContactNumber}
                placeholder="Enter contact number for IVR"
                variant="standard"
                error={!!errors.ivr_ContactNumber || duplicateIVR}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={TelephoneIcon}
                        style={{ color: "#000000" }}
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999",
                  },
                  "& label": {
                    marginTop: "1%",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#999999",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
                onChange={(e) => {
                  setErrors((prev) => {
                    return { ...prev, ivr_ContactNumber: "" };
                  });

                  ivrPatternSignup(e);
                }}
              />
              {duplicateIVR ? (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  IVR Number Already Registered
                </FormHelperText>
              ) : (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {contactErrorText}
                </FormHelperText>
              )}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "2%" }}
            >
              <TextField
                autoComplete="off"
                fullWidth
                id="filled-required-mobileNumber"
                label="Mobile Number *"
                name="mobileNumber"
                value={mobileNumber}
                placeholder="Enter mobile number for OTP verification"
                variant="standard"
                error={!!errors.mobileNumber || duplicateMobile}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={MobileIcon}
                        style={{ color: "#000000" }}
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999",
                  },
                  "& label": {
                    marginTop: "1%",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#999999",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
                onChange={(e) => {
                  setErrors((prev) => {
                    return { ...prev, mobileNumber: "" };
                  });

                  mobilePatternSignup(e);
                }}
              />
              {duplicateMobile ? (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  Mobile Number Already Registered
                </FormHelperText>
              ) : (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {mobileErrorText}
                </FormHelperText>
              )}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "2%" }}
            >
              <TextField
                autoComplete="off"
                fullWidth
                id="filled-required-email"
                label="Email Address *"
                name="email"
                value={formData.email}
                placeholder="Enter email address for verification"
                variant="standard"
                error={!!errors.email || duplicateEmail}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={EmailIcon}
                        style={{ color: "#000000" }}
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999",
                  },
                  "& label": {
                    marginTop: "1%",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#999999",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
                onChange={(e) => {
                  setErrors((prev) => {
                    return { ...prev, email: "" };
                  });

                  onChangeValue(e);
                }}
              />

              {duplicateEmail ? (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {duplicateEmail}
                </FormHelperText>
              ) : (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {emailErrorText}
                </FormHelperText>
              )}
            </Grid>

            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "2%" }}
            >
              <TextField
                name="address"
                label="Address *"
                id="filled-required-address"
                autoComplete="off"
                value={formData.address}
                error={!!errors.address}
                onChange={(e) => {
                  setErrors((prev) => {
                    return { ...prev, address: "" };
                  });
                  onChangeValue(e);
                }}
                multiline
                fullWidth
                rows={2}
                placeholder="Enter address"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={LocationIcon}
                        style={{ color: "#000000", marginTop: "-25px" }}
                        alt=""
                      />{" "}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                sx={{
                  marginTop: "10px",
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999 !important",
                  },
                  "& label": {
                    marginTop: "-8px",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#999999",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
              />

              {/* <TextField
                autoComplete="off"
                fullWidth
                id="filled-required-address"
                label="Address"
                name="address"
                value={formData.address}
                variant="standard"
                error={!!errors.address}
                placeholder="Enter address"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <img
                        src={LocationIcon}
                        style={{ color: "#000000" }}
                        alt=""
                      />{" "}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999",
                  },
                  "& label": {
                    marginTop: "1%",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#999999",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
                onChange={onChangeValue}
              /> */}
            </Grid>

            <Grid
              container
              justifyContent={"space-between"}
              sx={{ paddingBottom: "2%" }}
            >
              <Grid
                autoComplete="off"
                item
                xl={5.5}
                lg={5.5}
                md={5.5}
                sm={5.5}
                xs={5.5}
              >
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    "label.Mui-focused": { color: "#999999" },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#999999",
                    },
                    "& label": {
                      marginTop: "1%",
                      fontSize: "18px",
                      fontWeight: "500",
                    },
                    "& input::placeholder": {
                      fontSize: "16px",
                      color: "#000000",
                    },
                    "@media (max-width: 600px)": {
                      "& input::placeholder": { fontSize: "12px" },
                    },
                  }}
                >

                  <InputLabel id="state-label" error={!!errors.state_name}>
                    State *
                  </InputLabel>
                  <Select
                    labelId="state-label"
                    id="state-select"
                    value={formData.state}
                    error={!!errors.state_name}
                    onChange={(e)=> {
                      setErrors((prev) => {
                        return { ...prev, state_name: "" };
                      });
                      onChangeState(e)}}
                    MenuProps={{ PaperProps: { style: { maxHeight: "20%" } } }}
                    startAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <img
                          src={LocationIcon}
                          style={{ color: "#000000" }}
                          alt=""
                        />
                      </InputAdornment>
                    }
                  >
                    <MenuItem
                      value={"default"}
                      disabled
                      sx={{ display: "none" }}
                    >
                      {" "}
                      <span style={{ color: "#999999" }}> Select state</span>
                    </MenuItem>
                    {state?.map((state, index) => (
                      <MenuItem
                        key={index}
                        value={state.state_uid}
                        name={state.state_uid}
                      >
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <FormHelperText style={{ color: "red" }}>{errors.state}</FormHelperText> */}
              </Grid>
              <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={5.5}>
              <TextField
                autoComplete="off"
                fullWidth
                id="city-select"
                label="Village/Town/City&nbsp;*"
                name="city"
                value={formData.city}
                variant="standard"
                error={!!errors.city}
                placeholder="Enter village/town/city"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <img
                        src={LocationIcon}
                        style={{ color: "#000000" }}
                        alt=""
                      />{" "}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "label.Mui-focused": { color: "#999999" },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#999999",
                  },
                  "& label": {
                    marginTop: "1%",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#999999",
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#000000",
                  },
                  "@media (max-width: 600px)": {
                    "& input::placeholder": { fontSize: "12px" },
                  },
                }}
                onChange={(e)=>{
                  setErrors((prev) => {
                    return { ...prev, city: "" };
                  });
                  onChangeValue(e)}}
              />

              </Grid>
              {/* <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={5.5}>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    "label.Mui-focused": { color: "#999999" },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#999999",
                    },
                    "& label": {
                      marginTop: "1%",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#999999",
                    },
                    "& input::placeholder": {
                      fontSize: "16px",
                      color: "#000000",
                    },
                    "@media (max-width: 600px)": {
                      "& input::placeholder": { fontSize: "12px" },
                    },
                  }}
                >
                  <InputLabel id="city-label" error={!!errors.city_name}>
                    City
                  </InputLabel>
                  <Select
                    labelId="city-label"
                    error={!!errors.city_name}
                    id="city-select"
                    value={formData.city_name || formData.city}
                    onChange={onChangeCity}
                    MenuProps={{ PaperProps: { style: { maxHeight: "20%" } } }}
                    startAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <img
                          src={LocationIcon}
                          style={{ color: "#000000" }}
                          alt=""
                        />{" "}
                      </InputAdornment>
                    }
                  >
                    <MenuItem
                      value={"default"}
                      disabled
                      sx={{ display: "none" }}
                    >
                      {" "}
                      <span style={{ color: "#999999" }}>Select City</span>
                    </MenuItem>
                    {city?.map((city, index) => (
                      <MenuItem key={index} value={city.city_uid}>
                        {city.city_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {/* <FormHelperText style={{ color: "red" }}>{errors.city}</FormHelperText> */}
              {/* </Grid> */}
            </Grid>

            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "4%" }}
            >
                <TextField
                 inputProps={{
                  maxLength: 20,
                  autocomplete: 'new-password',
                 
                }}
                  autoComplete='off'
                  fullWidth
                  id="filled-required-name"
                  name="name"
                  value={formData.name}
                  placeholder="Enter your username"
                  variant="standard"
                  label={
                    <span>
                      Username *
                      <IconButton
                        size="small"
                        color="#999999"
                        sx={{ marginLeft: 1 }}
                        onMouseEnter={() => setIsTooltipOpen(true)}
                        onMouseLeave={() => setIsTooltipOpen(false)}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                      <Tooltip
                        title="Username should be min 8 and max 20 characters."
                        arrow
                        open={isTooltipOpen}
                        placement="right"
                        sx={{ cursor: "default !important" }}
                      >
                        <span></span>
                      </Tooltip>
                    </span>
                  }
                  error={!!errors.name || duplicateUsername}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={UserIcon}
                          style={{ color: "#000000" }}
                          alt=""
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "label.Mui-focused": { color: "#999999" },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#999999",
                    },
                    "& label": {
                      marginTop: "1%",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#999999",
                    },
                    "& input::placeholder": {
                      fontSize: "16px",
                      color: "#000000",
                    },
                    "@media (max-width: 600px)": {
                      "& input::placeholder": { fontSize: "12px" },
                    },
                  }}
                  onChange={(e) => {
                    setUsernameErrorText("");
                    setDuplicateUsername("");
                    setErrors(prev=> {return{...prev,name:"" }})
                    setFormData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }));
                  }}
                />
              {duplicateUsername ? (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  This is already in use. Please try a different username
                </FormHelperText>
              ) : (
                ""
              )}

              {usernameErrorText ? (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {usernameErrorText}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingBottom: "2%" }}
            >
                <TextField
                 inputProps={{
                  maxLength: 12,
                  autocomplete: 'new-password',
                
                }}
                  autoComplete="off"
                  fullWidth
                  id="filled-required-check"
                  name="check"
                  value={formData.check}
                  placeholder="Enter your password"
                  variant="standard"
                  label={
                    <span>
                      Password *
                      <IconButton
                        size="small"
                        color="#999999"
                        sx={{ marginLeft: 1 }}
                        onMouseEnter={() => setIsPasswordTooltipOpen(true)}
                        onMouseLeave={() => setIsPasswordTooltipOpen(false)}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                      <Tooltip
                        sx={{ cursor: "default !important" }}
                        title="Password must have minimum 8 characters and maximum 12. Include at least 1 lowercase, 1 upper case, 1 numeric & 1 special character"
                        arrow
                        open={isPassworTooltipOpen}
                        placement="right"
                      >
                        <span></span>
                      </Tooltip>
                    </span>
                  }
                  error={!!errors.check }
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={LockIcon}
                          style={{ color: "#000000" }}
                          alt=""
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}{" "}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& input::-ms-reveal, & input::-ms-clear": {
                      display: "none",
                    },
                    "& label": {
                      marginTop: "1%",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#999999",
                    },
                    "& input::placeholder": {
                      fontSize: "16px",
                      color: "#000000",
                    },
                    "@media (max-width: 600px)": {
                      "& input::placeholder": { fontSize: "12px" },
                    },
                    "label.Mui-focused": { color: "#999999" },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#999999",
                    },
                  }}
                  onChange={(e)=> {                   
                     setErrors(prev=> {return{...prev,check:"" }})
                  onChangeValue(e)}}
                />
              {passwordErrorText ? (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {passwordErrorText}
                </FormHelperText>
              ) : (
                ""
              )}
              {/* <FormHelperText style={{ color: "red" }}>{errors.check}</FormHelperText> */}
            </Grid>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button disableRipple
                type="submit"
                sx={{
                  mt: 1,
                  mb: 4,
                  backgroundColor: "#41BA8F",
                  color: "#FFFFFF",
                  textAlign: "center",
                  border: "1px solid #FFF",
                  borderRadius: "50px",
                  height: "40px",
                  width: "200px",
                  textTransform: "uppercase",
                  alignContent: "center",
                  "&:hover": { background: "#41BA8F" },
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

                  
                }}
              >
                Register
              </Button>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: "url(" + PatientsImage + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      />
             <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
    </Grid>
  );
}
