import React,{useEffect, useState} from "react";
import {Box,Typography,Grid,Card,CardContent, } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// ------ custom file and custom css file -------
import DashBoardCardData from '../../../Constant/Dashboard.json'
import Header from "../../ReuseComponents/Header/Header";
import "./Dashboard.css";
import TableData from "./TableData";
import CollapsibleTable from "./MobtableData";
import Menu from "../../ReuseComponents/MobileMenu/Menu";
import Controller from "../../../Controller/ApiController";
import { Navigate } from "react-router";
import noConsultation from "../../../Assets/dashboard/noConsultation.png";
import { useDispatch, useSelector } from "react-redux";
import { updateActualDateData } from "../../Assets/Redux/features/StoreData/updateDate";
import { updateHeaderData } from "../../Assets/Redux/features/StoreData/headerData";
import { updateAppointmentData } from "../../Assets/Redux/features/StoreData/settingAppointmentData";
import UtilDateTime from "../../../Constant/utillDateTime";

export default function Dashboard() {
  const [width , setWidth] = useState("")
  const [height , setHeight] = useState("")
  const [topWidgetData, setTopWidgetData]= useState();
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [filterInQue, setFilterInQue ] = useState("");
  const [updateTopWidget, setUpdateTopWidget] = useState(0);
  const [availableToday, setAvailableToday] = useState("");
  const [ currentDateTime, setCurrentDateTime ] = useState(new Date());
  const [dateFilter, setDateFilter] = useState("")
  const formattedDay = new Intl.DateTimeFormat('en-US',{day:'2-digit',}).format(currentDateTime);
  const formattedMonth = new Intl.DateTimeFormat('en-US',{month:'long', year: 'numeric',}).format(currentDateTime);
  const currentDay = new Intl.DateTimeFormat('en-US',{ weekday: 'long',}).format(currentDateTime);
  const appointmentDatas = useSelector( (state) => state.settingAppointmentData.appointment_data)

  useEffect(()=>{
    console.log(appointmentDatas, "appointmentDashboardData");
  },[appointmentDatas])
  // const displayCurrentDatePrevDate = useSelector((state) => state)
  
  const dispatch = useDispatch();
  const storedDate = useSelector( (state) => state.dateFilter.date)

  useEffect(()=>{
    if(storedDate){

    }
  },[storedDate])

  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })


  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };


  let Doctor_id = localStorage.getItem('DoctorUid')


  const FetchData = async(day) => {
    handleLoaderOpen();
    console.log(day, "dayString")
    try{
      let res = await Controller.ApiController("GET",`/setting/${Doctor_id}`);
      handleLoaderClose();
      if (res.type === "success") {
        const getData = res.data;
        
       const today_availability = getData.availability_data.availability_days.includes(day.toLowerCase());
      //  setAvailableToday(today_availability ? "APPOINTMENT" : "NO_APPOINTMENT");
      //  dispatch(updateAppointmentData(today_availability ? "APPOINTMENT" : "NO_APPOINTMENT"))
      }
    }
    catch(error){
      handleLoaderClose();
      console.log(error);
    }
  }






  useEffect(() =>{
    // alert(JSON.stringify(updateCountValue))
    let dayString = currentDay;
    async function GetTopWidgetData() {
      handleLoaderOpen();
      
    var UID =  localStorage.getItem("DoctorUid")
    var queryParams = {
      "filter.date" : storedDate
    }
    let data = {}
    let response = await Controller.ApiController("GET", `/dashboard/${UID}`, "", queryParams )
    handleLoaderClose();
    if(response.type == "success"){
      data = response.data.count
      const date = new Date(storedDate)
      const options = { weekday: 'long' };
     dayString = date.toLocaleDateString('en-US', options);
      dispatch(updateHeaderData(response.data.booked_data));
    }
    setTopWidgetData(
      [
       { "id":1,
         "RequestTotal": data?.in_que || 0,
         "RequestType": "In Que",
         "backgroundColor": "#DA7314",
         "filter_key":"IN_QUE"
       },
   
       {
        "id":2,
        "RequestTotal": data?.check_out || 0,
         "RequestType": "Check-Out",
         "backgroundColor": "#22B175",
         "filter_key":"CHECK_OUT"
       },
       {
        "id":3,
        "RequestTotal": data?.not_arrived || 0,
         "RequestType": "Not Arrived",
         "backgroundColor": "#BC123E",
         "filter_key":"NOT_ARRIVED"
       },
       {
        "id":4,
        "RequestTotal": data?.open || 0,
         "RequestType": "Open Slots",
         "backgroundColor": "#2F78EC",
         "filter_key":"OPEN"
       }
     ]
   )
    console.log(response, "ggggg")
    FetchData(dayString)
    }
    GetTopWidgetData()
  }, [updateTopWidget, storedDate])

  useEffect(() =>{
    console.log(topWidgetData, "sss")
  }, [topWidgetData])


  useEffect (() => {
    console.log("width",width ,"height", height)
  },[])

  const handleCardClick = (cardId) => {  
    const selectedCard = topWidgetData.find(item => item.id === cardId);
    setFilterInQue(selectedCard ? selectedCard.filter_key : '');    
    
  };

  
  

  return (
    <>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><Header /></Grid>
    </Grid>
    {appointmentDatas == "APPOINTMENT" && <Grid container spacing={1}>
   {topWidgetData? topWidgetData.map(
    (requestData, index) => (
    <Grid key={index} item sm={6} md={3} lg={3} xs={6}>
      <Card  className="Card_shadow" sx={{width:{xs:"100%",sm:"100%",md:"100%",lg:"100%", xl:"100%"}, borderRadius:"15px",backgroundColor:requestData.backgroundColor}} >
        <div onClick={() => handleCardClick(requestData.id)}>
        <CardContent style={{ padding: "16px", cursor:"pointer" }}>
          <Grid container>
            <Grid item md={12} lg={12} >
              <Box sx={{color:"#EAEAEA"}}>
                <h2>{requestData.RequestTotal}</h2>
                <Typography
                  style={{ fontSize: "14px"}}
                >
                  {requestData.RequestType}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        </div>
      </Card>
    </Grid>
  )
  ):""}
  <br></br>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
  <TableData filterInQue={filterInQue} setUpdateTopWidget={setUpdateTopWidget} />
  </Grid>
  <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

   </Grid>}

       {/* _________________ No Appointment start _____________ */}
       {appointmentDatas == "NO_APPOINTMENT" &&  <div style={{marginTop:"15%"}}>
<div style={{display:"flex", justifyContent:"center"}}>
      <img src={noConsultation}  width={"155px"}/>
      
      </div>
      <Typography sx={{ fontSize: "20px", textAlign:"center", fontWeight:500, marginTop:"5px"}}>
      No consultation on {UtilDateTime.formatYearMonthAndDayAsInt(storedDate)}.
      </Typography>
      </div> }
      {/* _________________ No Appointment end _____________ */}
   </>
  );
}
