import React, { useEffect, useState } from "react";
import '../Forgotpassword/forgotpassword.css';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GliderLogo from "../../../Assets/qgliderlogo.png";
// import Doctor from "./../Login/Doctor.png";
import Doctor from "../../../Assets/LoginImage/Login_Qg.png";
import {  FormHelperText, InputAdornment } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import UserIcon from '../../../Assets/icons/userIcon.svg';
import MobileIcon from '../../../Assets/icons/Mobileicon.svg';
import { MuiOtpInput } from "mui-one-time-password-input";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../Configuration/Config";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import Controller from "../../../Controller/ApiController";

export default function ForgotPassword() {

  const [otp, setOtp] = useState("");
  const [uid, setUid] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpSentMessage, setOtpSentMessage] = useState("");
  const [ fieldShow, setFieldShow ] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("")
  const [otpNoEdit, setOtpNoEdit] = useState(false);
  const navigate = useNavigate();
  const [ invalidUser, setInvalidUser ] = useState();
  const [EnableSubmit,setEnableSubmit] = useState()
  const [ formData, setFormData ] = useState({
    mobilenumber:""
  })

  var initialStateErrors = {   
    mobileNumber: { required: false },
  };
  const [error, setError] = useState(initialStateErrors);

  const isMobileNumber = /^[6-9][0-9]{9}$/.test(formData.mobilenumber);
  const isEmail =/^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{3,50}$/.test(formData.mobilenumber);

  const inputIcon = isMobileNumber ? ( <img src={MobileIcon} style={{color:"#000000"}}/> ) :  ( <img src={UserIcon} style={{color:"#000000"}}/> );

  useEffect(() => {

    if(formData.mobilenumber != "" || formData.mobilenumber.length != 0){
      setEnableSubmit(false)
    }
    else{
      setEnableSubmit(true)
    }
    
  }, [formData]);

  // useEffect(() => {
  //   if (formData.mobilenumber != "" && error.mobileNumber.required) {
  //     setError({ ...error, mobileNumber: { required: false } });
  //   } else if (error.mobileNumber.required) {
  //     setError({ ...error, mobileNumber: { required: true } });
  //   }
  // }, [formData.mobilenumber]);


  const sendOTP = async (otpVerified) => {
    let data;
    let urlType;

    if (otpVerified === false) {
      urlType = "forgot_password";
      data = { user_name: formData.mobilenumber };
    } else {
      urlType = "verify_otp";
      data = {
        otp: otp,
        user_identifier: formData.mobilenumber,
        type: "FORGOT_PASSWORD",
      };
    }
    try {
      const formErrors = {};
  
      if (!formData.mobilenumber || !formData.mobilenumber.trim()) {
        formErrors.mobilenumber = "Enter user name.";
      }
      // if (formData.mobilenumber.length > 10) {
      //   formErrors.mobilenumber = "Mobile Number should not exceed 10 digits.";
      // }
  
      const hasErrors = Object.keys(formErrors).length > 0;
      setErrors(formErrors);
  
      if (!hasErrors) {
        let res = await Controller.ApiController("POST",`/doctor/${urlType}`, "", data);
       
          if (res.type === "success") {
            setOtpNoEdit(true);
            setOtp(res.data.otp + "");            
            setFieldShow(true);
            // if (isMobileNumber) {
              setOtpSentMessage(`OTP has been sent to your mobile number ending with ${res.data.mobile_number}`);
            // }
            if (urlType === "verify_otp") {
              console.log("Navigating to /resetpassword");
              navigate('/resetpassword');
              console.log(res);
              console.log("doctor_uid", res.data.doctor_uid);
              sessionStorage.setItem("doctor_uid", res.data.doctor_uid);
            }
          } else {
            console.log(res)
            
            if (res.status === 'FAILED') {
              let errorMsgHide = false;
              const errorData = res.error.message;
              const errorUserNameMobile = res.error.invalid_user_name;
              console.log(errorData);
          
              if (urlType === "forgot_password") {
                if (errorUserNameMobile) {                  
                  // console.log("Error for forgot_password API:", errorData);
                  // Alert("error", "This username or mobile number is not registered", errorData);
                  setErrorMessage(errorData);
                  console.log(res)
                  if(res.status_code == 404) {
                    Alert("error", "This username or mobile number is not registered")
                  }else {
                    Alert("error", res.error.message)
                  }                  
                } else {                  
                  setErrorMessage(" ");
                  errorMsgHide = true; 
                 
                  // console.log("Other error for forgot_password API:", errorData);
                  
                }
              } else if (urlType === "verify_otp") {
                if (errorData === "Invalid OTP") {
                  console.log("Error for verify_otp API:", errorData);
                  Alert("error", errorData);
                } else if(errorData === "OTP is Mandatory"){
                  console.log("Other error for verify_otp API:", errorData);
                  Alert("error", "Please enter your OTP")
                }
              } else {
                console.log("Unhandled error:", errorData);
              }
            } else {
              console.log("Unhandled error:", res);
            }
          }
      }
    }catch(error){
      console.log("Caught an error:",error);
    

    }
  };
  

  const resendOTP = async () => {
    let data = { user_identifier: formData.mobilenumber, type: "FORGOT_PASSWORD"} 
    try{
      let res = await Controller.ApiController("POST","/doctor/resend_otp","", data);
      console.log(res);
        if(res.type === "success"){
          setOtpNoEdit(true);
          setOtp(res.otp + ""); 
         setOtpSentMessage(`OTP has been sent to your mobile number ending with ${res.data.mobile_number}`);
        }
      else{
        console.log("went wrong")
      }
    }
    catch(error){
      console.error("Error resending OTP:", error);
    }
  };

  const onChangeValue = (event) => {

      const { name, value } = event.target;
      let validInput = true;
      let errorMessage = "";
      if( name === "mobilenumber" || "username"){
        validInput = /^[ A-Za-z0-9_@./#&+-]*$/.test(value);
        errorMessage = validInput ? "" : "Entered Valid MobileNumber or UserName"
      }
      setFormData({ ...formData, [name]: value, });
      setErrors({...errors, [name]: errorMessage});
    
  };

  useEffect(() => {
    if(formData.mobilenumber != "" ) setErrorMessage(" ")
  },[formData.mobilenumber])

  return (
    <Grid container component="main" sx={{ height: "100vh", padding: "20px" }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6} elevation={6} sx={{ padding: { xl: "0px 120px", xs: "0px", lg: "0px 100px", md: "0px 50px",}, }} >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <img src={GliderLogo} alt={GliderLogo + ".logo"} width={"155px"}/>
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <h2 sx={{ fontWeight: "600", paddingBottom:"25px" }}>Forgot Password</h2>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", marginTop:"20px" }}>
            If you remember your password
            </Typography>
            <Box display={"flex"}>
              <Typography>You can</Typography>
              <Link to="/login"
                style={{textDecoration: "none", color: "#0C21C1", fontSize: "16px", fontWeight: "600", marginLeft:"4px" }}
              >
                Login here !
              </Link>
            </Box>
          </Box>
          <Box
            noValidate
            sx={{ mt: 4 }}
            autoComplete="off"
          >
            <Box>
            <TextField
              required
              fullWidth
              id="filled-required"
              label="Username or Mobile Number"             
              name="mobilenumber"
              value={formData.mobilenumber}
              placeholder="Enter Registered Username or Mobile Number"
              variant="standard"
              error={!!errors.mobilenumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {inputIcon}
                  </InputAdornment> 
                ),
                readOnly:otpNoEdit
              }}
              sx={{
                "label.Mui-focused": { color: "#999999" }, "& .MuiInput-underline:after": { borderBottomColor: "#999999" },
                paddingBottom: "4%",
                "& input::-ms-reveal, & input::-ms-clear": {
                  display: "none",
                },
                "& label": { marginTop: "-1%", fontSize: "18px", color:"#999999", fontWeight:"500" },
                "& input": {
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.Mui-focused fieldset":{display:"none"},
              }}
              onChange={onChangeValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  sendOTP(fieldShow);
                }
              }}
            />
           {/* <Typography className="errorValidationForgetPass">
                            {errorMessage}
                          </Typography> */}
                          </Box>
            {/* {  invalidUser ? (<FormHelperText style={{ color: "#d32f2f" }}>Please Enter Valid User Name</FormHelperText>) : ""} */}
            { fieldShow &&(
              <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <MuiOtpInput 
                      name="otp"
                      value={otp}
                      onChange={(event)=>setOtp(event)}
                      TextFieldsProps={{
                        placeholder: "-",
                        inputProps: { style: { color: "#000000", fontSize: "20px", } },
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          sendOTP(fieldShow);
                        }
                      }}
                    />
                      {otpSentMessage && (
                          <Typography
                          sx={{
                              fontSize: "15px",
                              fontWeight: "400",
                              color: "#999999",
                              marginTop: "10px",
                              alignItems:"flex-start"
                          }}
                          >
                          {otpSentMessage}
                          </Typography>
                      )}
                      </Box>
            )}
           <div style={{display:"flex", justifyContent:"center"}}>
              <Button fullWidth type="submit" disabled={EnableSubmit}
                sx={{ mt: 3, mb: 2, backgroundColor: "#41BA8F",  color: "#FFFFFF", border: "1px solid #FFF", borderRadius: "50px", height: "40px",
                width:"200px",
                  "&:hover": { background: "#41BA8F" }, textTransform: "none"  }}
                onClick={()=>sendOTP(fieldShow)}
              >
                { !fieldShow ? "Send OTP" : "Verify"}
              </Button>
            </div>
            { fieldShow && (
            <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Typography sx={{ fontSize: "15px", fontWeight: "400" }}>
              Didn’t receive OTP?
            </Typography>
            <Button
              type="button"
              onClick={()=>resendOTP()}
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C21C1",
                textDecoration: "none",
                marginLeft: "5px",
                textTransform: "none"
              }}
            >
              Resend
            </Button>
          </Box>
            )}

          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: "url(" + Doctor + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      />
    </Grid>
  );
}
