import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  FormControl,
  Menu,
  MenuItem,
  Button,
  InputLabel,
  Typography,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";

// ------ custom import files --------
import "./TableData.css";
import TableCardData from "../../../Constant/Dashboard.json";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
// import RiEditBoxFill from "../../../../src/Assets/Images/AppoinmentIcon/editIcon.svg";
// import MdDelete from "../../../../src/Assets/Images/DeletePopup";
import BookAppointment from "../../../Popup/BookAppointment";
import DeleteAppointment from "../../../Popup/DeletePopup";
import PropTypes from "prop-types";
import Controller from "../../../Controller/ApiController";
import UtilDateTime from "../../../Constant/utillDateTime";
import { Alert } from "../../../Components/ReuseComponents/Alert/Alertmessage";
import {useDispatch, useSelector } from "react-redux";
import delayIcon from "../../../Assets/icons/timeDelay.svg"
import {appointmentReferesh} from "../../Assets/Redux/features/Appointment/appointmentReferesh";

export default function TableData(props) {
  
  const dispatch = useDispatch();
  const { opens,filterInQue, setUpdateTopWidget,  } = props;  
  
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [updateStatusData, setUpdateStatusData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [nextDayDate, setNextDayDate] = useState(false);

  const storedDate = useSelector( (state) => state.dateFilter.date)

  
  


   const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };


  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })

  const GetAppointmentList = async (pageNumber) => {
    handleLoaderOpen();
    const currentDate = new Date();
    // Convert to ISO string
    const startOfDayISOString = currentDate.toUTCString();
    let currentdate = new Date();    
   
    currentdate = currentdate.setHours(0, 0, 0, 0);
    if(new Date(currentdate).toISOString() == storedDate){
      currentdate = new Date().toISOString();
      setNextDayDate(true)
    } else {
      currentdate = storedDate;      
      setNextDayDate(false)
    }

    var UID = localStorage.getItem("DoctorUid");
    var appointment_query = {
      "filter.doctor_uid": UID, page: pageNumber + 1 , count: rowsPerPage,
      "filter.slot_status":filterInQue,
      "filter.slot_start_time":startOfDayISOString,
      "filter.keyword":searchKeyword,
      "filter.appointment_date" : storedDate
    };

    var slot_query = {
      page: pageNumber + 1 , count: rowsPerPage,"filter.appointment_date":storedDate, "filter.booking_status": filterInQue, "filter.keyword":searchKeyword,"filter.doctor_uid":UID,
    }
    if(filterInQue == "OPEN") {
      slot_query = { ...slot_query, "filter.slot_start_time": currentdate,  "filter.current_date" : storedDate || "", "filter.slot_status": ["OPEN","BLOCKED"]}
    }

    let response ;
    if(filterInQue == "OPEN" ){
      response = await Controller.ApiController(
        "GET",
        `/get_slot/${UID}`,
        "",
        slot_query
      );
    }else {
      response = await Controller.ApiController(
        "GET",
        "/appointment",
        "",
        slot_query
      );
      console.log(response,"response")
    }
    handleLoaderClose();
    if (response.type == "success") {
      setAppointmentData(response);
    }
    else {
      setAppointmentData({})
    }
    console.log(response,"dashboarddataList");
  }

  useEffect(() => {
    GetAppointmentList(0); //Sending page value in parameter
    
    setPage(0);
  }, [count, rowsPerPage, filterInQue, searchKeyword, storedDate]);

  const refereshPage = () => {
    setCount((current) => current + 1);
    setUpdateTopWidget((current) => current + 1);
  }

  useEffect(() => {
    dispatch(appointmentReferesh(refereshPage));
  }, []);

  useEffect(() => {
    console.log(appointmentData, "appData");
  }, [appointmentData]);

  


  const UpdateStatus =  async function (status, data) {
    handleLoaderOpen();
    
     let updateData = {
      "booking_status": status
      }
    // alert(JSON.stringify(datas));  
    // alert(data?.appointment_uid);
    let res = await Controller.ApiController(
      "PUT",
      `/appointment/${data.appointment_uid}`,"", updateData
    );
    handleLoaderClose();
    if (res) {
      console.log(res, "dataListApi");

      if (res.type === "success") {
        handleClose()
        setCount((current) => current + 1);
        setUpdateTopWidget((current) => current + 1);
        // setAppointmentAddUpdate(prev => alert(prev));
        handleLoaderClose();
        Alert("success", "Appointment updated successfully");
      } else {
        handleLoaderClose();
        Alert("error", res.error.message);
      }
    }
  
}



  const handleChange = (event) => {
    console.log("changed", event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState("")
  const open = Boolean(anchorEl);
  const handleClick = (event,index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleChangePage = () => {};

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteOpenModal = () => {
    setDeleteModal(true);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const handleChangePage = (event, value) => {
    setPage(value);    
    GetAppointmentList(value);
    console.log(value)
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const convertToTitleCase = (inputString) => {
    const words = inputString.toLowerCase().split("_");

    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString;
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          <Box sx={{ padding: "10px 0px 0px 0px", marginBottom:"20px", display:{md:"flex",sm:""} }}>
            <h3>Appointments</h3>
           {appointmentData?.check_in_status?.patient_name && <div style={{display:"flex",  marginTop: height > width ? "1.5%" : "",}}>
              <div style={{marginLeft: height > width ? "1%" : "10%", marginTop:"8px"}}>
            <span class="sun sunshine"></span>
            <span class="sun"></span></div>
            <span style={{marginLeft:"32px", marginTop:"3px", whiteSpace:"nowrap", fontWeight:600}}>Patient Checked In : {appointmentData?.check_in_status?.patient_name || ""} &nbsp;({appointmentData?.check_in_status?.token || ""})</span>
           </div> }
          </Box>
        </Grid>
        <Grid
          sx={{ padding: "5px 0px 0px 0px" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <BioDetails />
        </Grid>
        <Grid sx={{textAlign: { md: "right", xl: "right", lg: "right", xxl: "right" }, display:"flex", justifyContent:"right" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mb={2}
        >
        {/* <div style={{ backgroundColor: "#EFEFEF", borderRadius: "50px", marginLeft:"-10px"}}> */}
          <TextField
          autoComplete="new-password"
            aria-label="Search"
            type="text"
            size="small"
            className="searchinput"
            value={searchKeyword}
            placeholder="Search"
            onChange={(e)=> setSearchKeyword(e.target.value)}
            sx={{
              height:"40px",
              paddingTop:"0px",
              borderRadius: "50px",
              marginLeft:{ xs:"20px"},
              backgroundColor: "#EFEFEF",
              "&:hover": {
                borderColor: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#fff",
                display: "none",
              },
              "&:focus": {
                borderColor: "#fff",
                // display:"none"
              },
            }}
            inputProps={{
              style: {fontSize: 14},
              autocomplete: 'off',
              form: {
                autocomplete: 'off',
              },}}
            InputProps={{
              autoComplete: 'new-password',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon sx={{ color: "#757575" }} />
                </InputAdornment>
              ),
              endAdornment: searchKeyword && (
                <InputAdornment>
                  <CancelIcon onClick={() => setSearchKeyword("")} sx={{ cursor: "pointer" }} />
                </InputAdornment>
              ),
            }}
          />
          {/* </div> */}
        </Grid>
        {/* {height > width ? (
          <Grid item xs={4} sm={4}>
            <Button variant="contained" startIcon={<FilterAltOutlinedIcon />}>
              Filter
            </Button>
          </Grid>
        ) : (
          ""
        )} */}
      </Grid>
      <Grid sx={{ padding: "5px 0px 0px 0px", marginBottom:{xs:"50px", md:"0px"} }} container spacing={1} >
        <Grid item sm={12} md={12} lg={12} >
          <Paper sx={{ width: {sm:"100%", xs:"380px"}}}>
          <TableContainer>
            <Table stickyHeader 
              sx={{ minWidth: {sm:"800px", xs:"300px"}}}
              aria-label="customized table"
            >
              <TableHead >
                <TableRow sx={{ whiteSpace:{sm:"normal", xs:"nowrap"}}}>
                  {TableCardData.tableRowHead.map((RowHeadData, index) => (
                    <TableCell
                      style={{
                        color: "#676666",
                        fontSize: "14px",
                        padding: "10px",
                        // width:"550px",
                        backgroundColor: "#EEF0FA",
                        
                      }}
                      key={index}
                    >
                      {RowHeadData.TblHead}{" "}
                      {RowHeadData.TblHead === "Status" }
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody  stickyHeader >
                {appointmentData?.data?.length > 0 ?
                   (appointmentData?.data?.map((RowCellData, index) => (
                      <TableRow key={index}>
                        <TableCell className="RowCellData">
                          {(index + 1 + ((appointmentData.current_page - 1) * rowsPerPage))}
                        </TableCell>
                        <TableCell
                          sx={{ height: "50px" }}
                          className="RowCellData"
                        >
                          {UtilDateTime.formatTimeAsHHMMTT(
                            RowCellData?.from_time || RowCellData?.slot_start_time || "--"
                          )}
                             {RowCellData?.is_delay && 
                        <Tooltip placement="right" title={`Initial booking time is ${UtilDateTime.formatTimeAsHHMMTT(RowCellData?.start_time)}`}>
                        <span style={{cursor:"pointer"}}>
                          <img src={delayIcon} style={{color: "#2C73EB", marginLeft:"10px"}}/>
                        </span>
                        </Tooltip>
                            }
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData?.token || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData.patient_name || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData.mobile_number || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          { RowCellData?.in_que_time != null ? UtilDateTime.formatTimeAsHHMMTT(RowCellData.in_que_time || "---") : "---"}
                        </TableCell>
                       
                        <TableCell className="RowCellData" >

                       
                          {RowCellData?.booking_status && !["OPEN","CHECK_OUT", "CANCELLED","BULK_CANCELLED"].includes(RowCellData?.booking_status) ? (
                            <Box>
                              <Button
                                id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, index)}
                                sx={{ padding: "0px" }}
                                aria-readonly
                              >
                                <Box
                                  sx={{
                                    color:RowCellData.booking_status == "NOT_ARRIVED" ? "#BC123E" : RowCellData.booking_status == "IN_QUE" ? "#DA7314" : RowCellData.booking_status == "CHECK_OUT" ? "#22B175" :  RowCellData.booking_status == "CHECK_IN" ? "#22B175" : RowCellData.booking_status == "CANCELLED" ? "#ff0000" : "black",
                                    textAlign: "left",
                                    width: "105px",
                                    textTransform: "none"
                                  }}
                                >
                                  {RowCellData.booking_status && convertToTitleCase(RowCellData?.booking_status)}
                                </Box>
                                <Box
                                  sx={{
                                    position: "relative",
                                    top: "3px",
                                    color: "black",
                                  }}
                                >
                                  <KeyboardArrowDownIcon />
                                </Box>
                              </Button>
                              {menuIndex == index && 
                              <Menu
                                sx={{ boxShadow: "none" }}
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                               {RowCellData.booking_status ==  "IN_QUE" && 
                                <MenuItem
                                  sx={{ color: "#22B175" }}
                                  onClick={()=>UpdateStatus("CHECK_IN", RowCellData )}>
                                  Check-In
                                </MenuItem>
                                }

                              {RowCellData.booking_status ==  "CHECK_IN" && 
                                <MenuItem
                                  sx={{ color: "#22B175" }}
                                  onClick={()=>UpdateStatus("CHECK_OUT", RowCellData )}>
                                  Check-Out
                                </MenuItem>
                                }
                               
                              {(RowCellData.booking_status ==  "NOT_ARRIVED" ) &&  nextDayDate &&
                               <MenuItem
                                  sx={{ color: "#DA7314" }}
                                  onClick={()=>UpdateStatus("IN_QUE", RowCellData )}>
                                  In Que
                                </MenuItem>
                                } 
                              {(RowCellData.booking_status == "IN_QUE" || RowCellData.booking_status =="NOT_ARRIVED") &&
                                 <>{nextDayDate && <Divider />}
                                <MenuItem
                                  sx={{ color: "#ff0000" }}
                                  onClick={()=>UpdateStatus("CANCELLED", RowCellData )}>
                                  Cancelled
                                </MenuItem></>
                                 }
                              </Menu>
                              }
                            </Box>
                          ) : RowCellData?.booking_status ? (
                            <div sx={{  }} >
                              <p style={{fontSize:"14px", padding:"0px", height:"0px", marginBottom:"0px",  marginTop:"-7px",  border: "none", 
                            color:RowCellData.booking_status == "NOT_ARRIVED" ? "#BC123E" : RowCellData.booking_status == "IN_QUE" ? "#DA7314" : RowCellData.booking_status == "CHECK_OUT" ? "#22B175" : RowCellData.booking_status == "CHECK_IN" ? "#22B175" :RowCellData.booking_status == "CANCELLED" ? "#ff0000" :RowCellData.booking_status == "BULK_CANCELLED" ? "#ff0000" : "black",
                            }}>{convertToTitleCase(RowCellData?.booking_status)}</p>
                            </div>
                          ) :(
                            <p sx={{ textAlign: "left" }} >
                              {convertToTitleCase(RowCellData?.slot_status)}
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ): (
                  <TableRow>
                   <TableCell
                      sx={{ textAlign: { md: "center", xs: "start" } }}
                      colSpan={7}>
                      No Appointment Found
                    </TableCell>
                  </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          </Paper>

          {appointmentData.total_records > 0 &&
          <TablePagination             
              component="div"
              count={appointmentData.total_records}
              rowsPerPage={rowsPerPage}
              page={page}             
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        </Grid>
      </Grid>
      {/* {openModal && (<BookAppointment name="EditUser" type="editUser" open={openModal} onClose={handleCloseModal}/>)} */}
      <DeleteAppointment
        openModalDelete={deleteModal}
        closeModalDelete={handleDeleteCloseModal}
      />

<Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
    </>
  );

  // TableData.propTypes = {
  //   page: PropTypes.number,
  //   : PropTypes.function,
  //   list: PropTypes.function,
  // };
}
