import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import { Box, Grid, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import CustomizedButtons from "../../ReuseComponents/Buttons/ColorButton";
import Popup from "../../ReuseComponents/Popup/Popup";
import { useNavigate } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import Header from "../../ReuseComponents/Header/Header";

export default function QRGenerator() {
  let doctor_id = localStorage.getItem("DoctorUid");
  const [value, setValue] = useState("");
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(256);
  const navigate = useNavigate();
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [userDataList, setUserDataList] = useState("");
  const [urlLink, setUrlLink] = useState("https://uat.qglider.com/")

  const navigatePrevious = () => {
    navigate(-1);
  };

  const ScanBaseUrl = () => {
    if(window.location.href.includes("uat")){
      setUrlLink("https://uat.qglider.com/")
    }else if(window.location.href.includes("uat")){
      setUrlLink("https://uat.qglider.com/")
    }
    console.log(window.location.href.includes("uat"))
  }

  // useEffect(() => {
  //   // ScanBaseUrl();
    
  //   setUrlLink("https://uat.qglider.com/")
  // },[])

  const FetchData = async () => {
    
    setValue( urlLink+"patientqrscan/"+doctor_id);    
   
  };

  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })

  useEffect(() => {
    FetchData();
  }, []);

  return (
    
    <div style={{marginBottom:0}}>
       <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><Header /></Grid>
    </Grid>
      <BreadCrumbs
        crumbs={["Dashboard", "Generate QR Code"]}
        paths={["/dashboard", "/generateQR"]}
        selected={(crumb) => console.log("hello", crumb)}
      />
      {width >  height ? (
      <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"25%"}}>
        <Popup>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={{
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "70vh",
              xl: "70vh",
              xxl: "50vh",
            }}
            width={{
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "25vw",
              xl: "20vw",
              xxl: "20vw",
            }}
          >
            <Box>
              <Typography
                fontSize={"35.11px"}
                mb={3}
                fontFamily={"Poppins"}
                color={"#243665"}
                fontWeight={700}
              >
                Scan QR Code
              </Typography>
              {value && (
                <QRCode
                  title="QGLIDER"
                  value={value}
                  bgColor={back}
                  fgColor={fore}
                  size={size === "" ? 0 : size}
                />
              )}
              <Box
                display={"flex"}
                justifyContent={"center"}
                mt={6}
                onClick={navigatePrevious}
              >
                <CustomizedButtons
                  bgColor={"#1971FF"}
                  color={"#white"}
                  text={"Back"}
                  hoverColor={"#1971FF"}
                  style={{
                    borderRadius: "50px",
                    width: "100%",
                    pl: 6,
                    pr: 6,
                    fontSize: "15px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Popup>
      </Box> 
      ) : (


       <Box sx={{marginTop:"25%", marginBottom:0}}>
              <Typography
                fontSize={"35.11px"}
                fontFamily={"Poppins"}
                color={"#243665"}
                fontWeight={700}
                sx={{textAlign:"center"}}
              >
                Scan QR Code
              </Typography>
              {value && (
                <div   style={{alignContent:"center", alignItems:"center", display:"flex", justifyContent:"center"}}>
                <QRCode
                 title="QGLIDER"
                  value={value}
                  bgColor={back}
                  fgColor={fore}
                  size={size === "" ? 0 : size}
                /></div>
              )}
            </Box>)}
    </div>
  );
}
